

























import { useBookedSchedules } from '@/composables/useBookedResources';
import MemberPage from '@/layouts/MemberLayout/MemberPage.vue';
import { Schedule } from '@/models/booked/Schedule';
import { defineComponent, onMounted, ref } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router/useRouter';
import { useAuthStore } from '@/composables/useAuthStore';

export default defineComponent({
  components: { MemberPage },
  name: 'BookSpacePage',
  setup() {
    const schedules = ref<Schedule[]>([]);
    const { getSchedules } = useBookedSchedules();
    const { router } = useRouter();
    const { refreshState } = useAuthStore();

    onMounted(async () => {
      await refreshState();
      schedules.value = await getSchedules();
    });

    const bookForSchedule = (id: number) => {
      router.push({ name: routeNames.bookingsCalendar, query: { id: id.toString() } });
    };

    return {
      schedules,
      bookForSchedule,
    };
  },
});
